<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <br />

    <v-card class="card">
      <v-card-title class="heading justify-center">Accession Register 2</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="3" md="3">
            <label>Accession Number</label>

            <v-text-field class="text" v-model="accno" persistent-hint dense outlined v-on="on"></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" md="2">
            <label class="add-text">Series</label>
            <v-autocomplete v-model="series" placeholder="Select Series" :items="series_data" item-text="name"
              item-value="id" outlined dense class="text" :rules="[rules.required]" required></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="1" md="1">
            <v-checkbox v-model="isrange" label="Range"></v-checkbox>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <br /><br />
            <v-text-field class="text" persistent-hint dense placeholder="From" type="number" outlined
              :disabled="!isrange" :readonly="!isrange" v-model="from"></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <br /><br />
            <v-text-field class="text" persistent-hint dense placeholder="To" type="number" outlined
              :disabled="!isrange" :readonly="!isrange" v-model="to"></v-text-field>
          </v-col>
        </v-row>

        <center class="ma-5">
          <v-btn color="success" @click="get_acc_register">Fetch Data</v-btn>
        </center>
      </v-card-text>
    </v-card>
    <v-skeleton-loader v-if="overlay" v-bind="attrs" type="divider, list-item-three-line, card-heading">
    </v-skeleton-loader>
    <div v-else-if='Array.isArray(orgbookdetails) && orgbookdetails.length'>
      <v-card>
        <template>

        </template>
        <v-card-title>
          <v-text-field v-model="search_book" append-icon="mdi-magnify" label="Search" single-line hide-details>
          </v-text-field>
          <v-spacer></v-spacer>
          <template>
             <excelheader :headers="header_org" :tabledata="orgbookdetails" report_name="Accession Register 2">
    </excelheader>
          </template>
        </v-card-title>

        <v-data-table :headers="header_org" :items="orgbookdetails" :search="search_book">
          <template v-slot:item.date_of_entry="{ item }">
            <div v-if="item || item.bill_date || item.bill_date != null || item.bill_date != 'null'">
              {{ item.date_of_entry.split("T")[0].split("-")[2] + "-" + item.date_of_entry.split("T")[0].split("-")[1] +
                  "-" + item.date_of_entry.split("T")[0].split("-")[0]
              }}
            </div>
          </template>
          <template v-slot:item.bill_date="{ item }">

            <div
              v-if="item && item.bill_date && item.bill_date != null && item.bill_date != 'null' && item.bill_date != ''">
              {{ item.bill_date.split("T")[0].split("-")[2] + "-" + item.bill_date.split("T")[0].split("-")[1] + "-" +
                  item.bill_date.split("T")[0].split("-")[0]
              }}
            </div>
          </template>
        </v-data-table>

      </v-card>
    </div>
   
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { table2excel } from "../jquery.table2excel";
import xlsx_header from "@/components/common_table_header.vue";
import VueBarcode from "vue-barcode";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  components: {
    "excelheader": xlsx_header
  },
  data: () => ({
    isrange: false,
    isaccno: true,
    isauthor: true,
    isdept: true,
    iscno: true,
    line1: false,
    line2: false,
    line3: false,
    from: 1,
    to: 100,
    fields: {
      Series: "series",
      "Accession No": "author",
      "Barcode No": "barcode_no",
      //"Barcode": "barcode",
      Barcode: {
        field: "barcode",
        callback: (item) => {
          return item == "" ? "" : `<barcode :value="item"></barcode>`;
        },
      },
    },

    overlay: false,
    selected: [],
    accno: "",
    errorMsg: {
      roleLinkId: false,
    },
    rules: {
      required: (value) => !!value || "Required.",
    },
    orgbookdetails: [],
    snackbar: false,
    snackbar_msg: "",
    color: "",
    search: "",
    search_book: "",
    series_data: [],

    series: "",

    header_org: [
      { text: "Sr. No.", value: "srno" },
      { text: "Display Order", value: "display_order" },
      { text: "Orgnization", value: "orgName" },
      { text: "Series", value: "Series" },
      { text: "Accession Number", value: "accession_number" },
      { text: "Date Of Entry", value: "date_of_entry" },
      { text: "Barcode", value: "barcode" },
      { text: "UID NO", value: "isbn" },
      { text: "Title", value: "title" },
      { text: "Author1", value: "author1" },
      { text: "Author2", value: "author2" },
      { text: "Author3", value: "author3" },
      { text: "Author4", value: "author4" },
      { text: "Author5", value: "author5" },
      { text: "Edition", value: "edition" },
      { text: "Medium", value: "medium" },
      { text: "Subject", value: "subject" },
      { text: "Classification NO", value: "cno" },

      { text: "Dept", value: "dept" },
      { text: "Pages", value: "pages" },
      // { text: "Book Format", value: "bookformat" },
      { text: "Book Type", value: "booktype" },
      // { text: "Book Category", value: "bookcategory" },
      { text: "Publisher", value: "publisher" },
      { text: "Publish year", value: "publisher_year" },
      { text: "Localtion", value: "location" },
      { text: "Bill NO", value: "billno" },
      { text: "Bill Date", value: "purchaseDate" },
      { text: "Actual Price", value: "actualprice" },
      { text: "Discount", value: "discount" },
      { text: "Price", value: "price" },
      { text: "Vendor", value: "vendor" },
      { text: "Remarks", value: "remark" },
      { text: "Status", value: "status" },
    ],

    single_org: {
      Sr_No: "srno",

      Orgnization: "orgName",
      Date_Of_Entry: "date_of_entry",
      Date_Of_Entry: {
        field: "date_of_entry",
        callback: (item) => {
          return item == ""
            ? ""
            : item.split("T")[0].split("-")[2] +
            "-" +
            item.split("T")[0].split("-")[1] +
            "-" +
            item.split("T")[0].split("-")[0];
        },
      },
      Series: "Series",
      Accession_Number: "accession_number",
      Barcode: "barcode",
      UID_NO: "isbn",
      Classification_NO: "cno",
      Title: "title",
      Author1: "author1",
      Author2: "author2",
      Author3: "author3",
      Author4: "author4",
      Author5: "author5",
      Edition: "edition",


      Dept: "dept",
      Pages: "pages",
      Medium: "medium",
      Book_Format: "bookformat",
      Book_Type: "booktype",
      Book_Category: "bookcategory",
      Publisher: "publisher",
      Publish_Year: "publisher_year",
      Display_Order: "display_order",
      Localtion: "location",
      Bill_NO: "billno",
      Bill_Date: "purchaseDate",
      Actual_Price: "actualprice",
      Discount: "discount",
      Price: "price",
      Remarks: "remark",
      Vendor: "vendor",
      Status: 'status'
    },
  }),
  computed: {
    filteredDesserts() {
      return this.dailyData.filter((d) => {
        return Object.keys(this.filters).every((f) => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },

  mounted() {
    this.onLoad();
  },
  methods: {

    exportexcel() {
      alert("test")
      $("#exceltable").table2excel({
        name: "Worksheet Name",
        exclude_img: false,
        filename: "Barcode_report", //do not include extension
        fileext: ".xlsx", // file extension
        preserveColors: false
      });
      // $("#export").click(function (e) {
      // window.open('data:application/vnd.ms-excel,' + encodeURIComponent($('#exceltable').html()));
      // e.preventDefault();
      // });
    },
    get_acc_register() {
      if (this.isrange && this.from > this.to)
        this.showSnackbar("red", "Please enter Valid range");
      if (this.isrange && (this.from == "" || this.to == ""))
        this.showSnackbar("red", "Please enter Valid range");
      else {
        this.overlay = true;

        const data = {
          accno: this.accno,
          series_id: this.series,
          isrange: this.isrange,
          from: this.from,
          to: this.to,
        };
        axios
          .post("/Librarian/get_acc_register", data)
          .then((res) => {
            if (res.data.msg == "200") {
              this.overlay = false;
              this.orgbookdetails = res.data.orgbookdetails;

            }
            else {
              this.showSnackbar("#b71c1c", res.data.msg); // show snackbar

              this.overlay = false;
              this.orgbookdetails = [];
            }
          })
          .catch((error) => {

            this.overlay = false;
          })
          .finally(() => { });
      }
    },
    onLoad() {
      this.overlay = true;
      axios
        .post("/Librarian/getseriesData")
        .then((res) => {
          if (res.data.msg == "200") {
            this.overlay = false;
            this.series_data = res.data.series_data;
            this.series_data.push({ name: "ALL", id: "ALL" });
            this.series = "ALL";
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
          this.overlay = false;
        })
        .finally(() => {

          this.overlay = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}

.fields {
  padding: 0px !important;
}

.cus-card {
  background: #d3d3d33d;
  margin: 1rem;
  padding: 1rem;
  border: 2px solid gray;
}

.c-label {
  font-weight: bold;
}

.add-section {
  display: block;
  margin: 1rem;
}

.text>>>.v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}

.elevation-1 /deep/ tr {
  white-space: nowrap !important;
}

.d-btn {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem;
}

.checkbox {
  margin: 1rem;
  border: 1px solid;
  background: #d3d3d380;
}

.checkbox .box {
  margin-top: 5px;
}

.checkbox /deep/ .v-input--selection-controls {
  margin-top: 0px !important;
}

.column {
  float: left;
  width: 50%;
  padding: 10px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
</style>
